.PhoneInputInput {
    border: none;
    outline: none;
}

.PhoneInputInput::placeholder{
    opacity: 0.5;
    color: rgb(0, 0, 0);
    font-family: inherit;
    font-size: inherit;
}