@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Google Sans', 'Inter', 'Open Sans', Arial, Helvetica, sans-serif, 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
body * {
  box-sizing: inherit;
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
html {
  --content-title-font-size: 2.25rem;
  --content-title-font-weight: 700;
  --content-description-font-size: 1rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --faq-color-background: #fafafa;
  --faq-dropdown-background: #fafafa;
  --faq-question-font-size: 1.1rem;
  --faq-question-font-size-bold: 1.3rem;
  --faq-sub-question-font-size: 1rem;
  --faq-answer-font-size: 0.9rem;
  --color-primary: #5c8aff;
  --color-black: #000000;
  --size-border-radius-h: 1rem;
  --color-gray-400: #dcdcdf;
  --color-white: #ffffff;
  --color-gray-600: #656571;
  --size-spacing-s: 0.75rem; /* 12px  */
  --size-spacing-l: 1.25rem; /* 20px  */
  --color-light: #fff;
  --button-radius: 0.65rem;
  --color-primary-dark: #5c8aff;
  --size-font-l: 1rem; /* 16px */
  --faq-open-answer-background: #ffffff;
  --faq-dropdown-color: #000000;
  --color-gray-400: #dcdcdf;
  --faq-dropdown-background: #fafafa;
  --faq-question-font-size: 1.1rem;
  --color-black: #000;
}
@media screen and (max-width: 425px) {
  html {
    --faq-question-font-size: 0.87rem;
    --faq-question-font-size-bold: 1rem;
    --faq-sub-question-font-size: 0.8rem;
    --faq-answer-font-size: 0.8rem;
  }
}
