.cancel-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
  }
}
.demo-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}
.demo-page-form {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  padding: 1rem 1rem;
  .captcha-button {
    margin-bottom: 10px;
  }

  .demopage-wrapper {
    display: flex;
    gap: 30px;
    .right-container {
      width: 500px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      .text1 {
        font-size: 40px;
        font-weight: 600;
      }
      .text2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
}
.success-image {
  width: 431.68;
  height: 316;
  margin-left: 180px;
}
.success-msg {
  font-weight: 600;
  margin-left: 170px;
  margin-top: 20px;
}
.successpage-wrapper {
  width: 800px;
  justify-content: center;
  padding-bottom: 20px;
}
.successpage-wrapper-mobile {
  padding-bottom: 20px;
}
.success-image-mobile {
  width: 200px;
  height: 200px;
  margin-left: 50px;
}
.success-msg-mobile {
  font-size: small;
  text-align: center;
  margin-top: 10px;
}
.demo-page-form-label-fields {
  display: flex;
  gap: 30px;
  .field-wrapper {
    text-align: start;
  }
  .name-input-wrapper,
  .email-input-wrapper,
  .phonenumber-input-wrapper,
  .domain-input-wrapper {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    .error-message {
      font-size: 10px;
      font-weight: 400;
      color: red;
    }
  }
}

.demo-page-form-input-fields {
  display: flex;
  gap: 1rem;

  .phonenumber-input-wrapper {
    position: relative;
  }
}
.demo-page-form-field-name {
  margin-bottom: 4px;
  opacity: 1;
  color: rgba(54, 64, 74, 1);
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
}
.demo-page-form-fields {
  display: flex;
  flex-direction: column;
}
.demo-page-form-text {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
  padding: 0.6rem 0;
}
.form-wrapper {
  width: 500px;
}

.demo-page-form-text-contact-us {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 30px;
  padding: 0.6rem 0;
}
.demo-page-checkbox {
  opacity: 1;
  color: rgba(54, 64, 74, 1);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 14px;
}
.demo-page-input-checkbox-text {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.demo-page-form-inputs {
  padding: 1rem;
  margin-bottom: 0.2rem;
  border-radius: 10px;
  width: 100%;
  min-width: 232px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(35, 41, 53, 0.196432);
  &:focus-within {
    border: 2px solid rgba(99, 128, 255, 1);
  }
  ::placeholder {
    opacity: 0.3;
    color: rgba(54, 64, 74, 1);
    font-family: "Inter-Regular";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 20px;
  }
}
.demo-page-form-inputs-description {
  padding: 1rem;
  margin-bottom: 16px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(35, 41, 53, 0.196432);
  resize: none;
}
.demo-page-submit-button {
  cursor: pointer;
  height: 35px;
  background-color: rgba(99, 128, 255, 1);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
}
.demo-page-submit-button-mobile {
  cursor: pointer;
  height: 35px;
  background-color: rgba(99, 128, 255, 1);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
}
.demo-page-submit-button-disabled {
  height: 40px;
  background-color: #6380ff;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: not-allowed;
  opacity: 50%;
}
.demo-page-form-mobile {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  .captcha-button {
    margin-bottom: 10px;
    -webkit-transform: scale(0.8);
    transform: scale(0.7);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
}
.demopage-wrapper-mobile {
  max-height: 550px;
  overflow-y: scroll;
  .demo-page-submit-button {
    cursor: pointer;
    height: 35px;
    background-color: rgba(99, 128, 255, 1);
    color: #fff;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 14px;
    width: fit-content;
  }
  .demo-page-submit-button-disabled {
    height: 40px;
    background-color: rgba(99, 128, 255, 1);
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: not-allowed;
  }
  .right-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding-bottom: 16px;
    .PopupImage {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text1 {
      font-size: 20px;
      font-weight: 600;
    }
    .text2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.demo-page-form-label-fields-mobile {
  display: flex;
  flex-direction: column;
  .demo-page-form-field-name {
    opacity: 1;
    color: rgb(54, 64, 74);
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    .error-message {
      color: red;
    }
  }
  .demo-page-form-inputs {
    padding: 1rem;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(35, 41, 53, 0.196432);
    &:focus-within {
      border: 2px solid rgba(99, 128, 255, 1);
    }
  }
  .demo-page-form-inputs-description {
    padding: 1rem;
    margin-bottom: 16px;
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(35, 41, 53, 0.196432);
    resize: none;
  }
}
@media screen and (max-width: 960px) {
  .demopage-wrapper {
    display: flex;
    flex-direction: column;
  }
  .demo-page-form-inputs {
    outline: none;
    &:focus {
      border: 2px solid rgba(99, 128, 255, 1);
    }
  }
  .demo-page-form-inputs-description {
    outline: none;
    &:focus {
      border: 2px solid rgba(99, 128, 255, 1);
    }
  }
  .demo-page-form-mobile {
    padding: 0.5rem 0.5rem;
  }
}
