.landing-page-headingv1 {
  background: rgb(92, 138, 255, 1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 1rem 13px 0;
  align-items: center;
  @media screen and (max-height: 900px) {
    padding: 13px 7rem 13px 0;
  }
  .logo-icon {
    cursor: pointer;
    padding-left: 5rem;
  }
  .landing-page-heading-text-align {
    display: flex;
    gap: 4rem;
    height: 30px;
    align-items: center;
  }
  .landing-page-center-text {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-size: 50px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: center;
    line-height: 59px;
    padding: 2rem;
  }
  .landing-page-heading-text {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: -0.05px;
    text-align: center;
    cursor: pointer;
    margin: unset;
  }
  .buttons {
    display: flex;
    gap: 10px;
    .landing-page-sign-up-buttonv1 {
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 1);
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      background-color: unset;
      padding: 1.2rem 0.5rem;
      .landing-page-sign-up-text {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -0.05px;
        text-align: center;
        line-height: 24px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .landing-page-getstarted-up-buttonv1 {
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 1);
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      background-color: unset;
      padding: 1.2rem 0rem;
      background-color: rgba(255, 255, 255, 1);

      .landing-page-sign-up-text {
        opacity: 1;
        color: rgba(99, 128, 255, 1);
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -0.05px;
        text-align: center;
        line-height: 24px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
.landing-page-heading-wrapper {
  max-width: 1500px;
  margin: auto;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 0 13px 0;
  align-items: center;
  a {
    text-decoration: none;
  }
}
.landing-page-heading-mobile {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  background: rgb(92, 138, 255);
  gap: 50px;
  z-index: 5;
  width: 100%;
  align-items: center;

  .icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .buttons {
    display: flex;
    gap: 10px;
    .landing-page-sign-up-buttonv1 {
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 1);
      cursor: pointer;
      height: 30px;
      display: flex;
      align-items: center;
      background-color: unset;
      padding: 1.2rem 0rem;
      .landing-page-sign-up-text {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        text-align: center;
      }
    }

    .landing-page-getstarted-up-buttonv1 {
      border-radius: 10px;
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
      cursor: pointer;
      height: 30px;
      border: none;
      display: flex;
      align-items: center;
      padding: 1.2rem 0.3rem;
      .landing-page-sign-up-text {
        opacity: 1;
        color: rgba(99, 128, 255, 1);
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: -0.05px;
        text-align: center;
        line-height: 24px;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
