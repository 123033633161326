.login-modal {
  padding: 1rem 1rem;
  .backgroundImg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
.login-modal-welcome-to-eu {
  opacity: 1;
  color: rgba(50, 64, 84, 1);
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
}
.login-modal-notes {
  padding-top: 1.5rem;
  width: 500px;
  .point {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
    img {
      width: 16px;
      height: 16px;
    }
  }
}

.login-modal-welcome-notes {
  color: rgb(50, 64, 84, 1);
  font-size: 20px;
  padding-bottom: 1rem;
  opacity: 1;
  text-align: left;
}
.login-modal-container {
  display: flex;
  margin-left: 3rem;
  margin-right: 3rem;
  gap: 2rem;
}
.login-modal-welcome-icon {
  text-align: center;
}
.login-modal-cancel-icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.login-modal-signIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  .microsoft-sign-in-button {
    border: 1px solid rgba(50, 64, 84, 0.15);
    background-color: rgba(255, 255, 255, 1);
    padding: 0px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 16px;
    width: 200px;
    flex-direction: row-reverse;
    gap: 0.5rem;
    height: 32px;
    .sign-in-with-microsoft-text {
      color: #3c4043;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      display: block;
    }
    .microsoft-icon-container {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
    &:hover {
      background-color: rgba(66, 133, 244, 0.04);
    }
  }
}
@media only screen and (max-width: 960px) {
  .login-modal {
    padding: 0.5rem 0.5rem;
  }
  .login-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    gap: 0rem;
    .backgroundImg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .login-modal-welcome-to-eu {
    font-size: 22px;
  }
  .login-modal-notes {
    padding-top: 1.5rem;
    width: auto;
    .point {
      align-items: flex-start;
      gap: 8px;
    }
  }
  .login-modal-welcome-notes {
    text-align: left;
    font-size: 14px;
    color: unset;
  }
}
