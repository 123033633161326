.ModalPopUp {
  .modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);

    .modal-content {
      padding: 25px;
      border-radius: 10px;
      border: 1px solid #e2e4e7;
      position: relative;
      background-color: #fefefe;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19);
      animation-name: animatetop;
      animation-duration: 0.4s;
    }
    @keyframes animatetop {
      from {
        top: -300px;
        opacity: 0;
      }

      to {
        top: 0;
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .modal-content {
    padding: 5px 12px 12px 12px !important;
    max-width: 90%;
  }
}
